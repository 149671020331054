<template>
  <mex-dialog :showDialog="showReferenceDialog" dialogColor="foreground" dialogTitle="Reference Data" width="50%">
    <template v-slot:dialog-content>
      <v-row justify="center">
        <v-col class="align-stretch" cols="12">
          <v-textarea dense label="Reference Data" :value="selectedReferenceContent" outlined rows="15" readonly></v-textarea>
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Done" icon="mdi-check" @click="discardReferenceDialog" />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>

export default {
  name: 'ReferenceDialog',
  props: {
    showReferenceDialog: {
      type: Boolean,
      default: false,
    },
    selectedReferenceContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    discardReferenceDialog() {
      this.$emit('resolve');
    },
  },
};
</script>

<style></style>
