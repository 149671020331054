import httpClient from '../httpClient/httpClient';

const LocationFeaturesService = {
  getLocationFeatures(locationID, startDate, endDate) {
    return httpClient.get('api/get-location-features', {
      params: {
        locationID,
        startDate,
        endDate,
      },
    });
  },
  getMostRecentLocationFeatures(locationID) {
    return httpClient.get('api/get-most-recent-location-features', {
      params: {
        locationID,
      }
    })
  },
  getFeatureLocationOverview(retrievalDate) {
    return httpClient.get('api/get-feature-location-overview', {
      params: {
        retrievalDate
      }
    });
  },
  getLocationFeaturesOfClinic(clinicID) {
    return httpClient.get('api/get-location-features-of-clinic', {
      params: {
        clinicID
      }
    });
  }
};

export default LocationFeaturesService;
