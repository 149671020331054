<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Feature License View" />
      </v-col>
    </v-row>
    <mex-sperm-spinner
      v-if="featureLocationOverviewLoading"
      :spinnerText="featureLocationOverviewLoadingText"
    />
    <div v-else>
      <v-tabs
        v-model="currentTab"
        background-color="foreground"
        color="primaryAccent"
        class="mt-5"
        grow
      >
        <v-tab>
          <v-row justify="center">
            <v-col align-self="center" cols="auto">Location</v-col>
            <v-col align-self="center" cols="auto">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col align-self="center" cols="auto">Feature</v-col>
          </v-row>
        </v-tab>
        <v-tab>
          <v-row justify="center">
            <v-col align-self="center" cols="auto">Feature</v-col>
            <v-col align-self="center" cols="auto">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col align-self="center" cols="auto">Location</v-col>
          </v-row>
        </v-tab>
        <v-tab>
          <v-row justify="center">
            <v-col align-self="center" cols="auto">Feature</v-col>
            <v-col align-self="center" cols="auto">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col align-self="center" cols="auto">Clinic</v-col>
          </v-row>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" style="background-color: var(--v-foreground-base)">
        <v-tab-item class="pa-5">
          <v-row justify="center">
            <v-col align-self="center" cols="3.5">
              <v-autocomplete
                v-model="selectedOrganization"
                :items="organizationNames"
                color="primaryAccent"
                dense
                hide-details
                label="Organization"
                outlined
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                    <v-list-item-subtitle class="ma-2">{{ data.item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col align-self="center" cols="3.5">
              <v-autocomplete
                v-model="selectedClinic"
                :filled="!selectedOrganization"
                :items="clinicNames"
                :readonly="!selectedOrganization"
                color="primaryAccent"
                dense
                hide-details
                label="Clinic"
                outlined
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                    <v-list-item-subtitle class="ma-2">{{ data.item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col align-self="center" cols="3.5">
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="selectedLocation"
                    :filled="!selectedClinic"
                    :items="locationNames"
                    :readonly="!selectedClinic"
                    color="primaryAccent"
                    dense
                    hide-details
                    label="Location"
                    outlined
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                        <v-list-item-subtitle class="ma-2">{{ data.item.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn v-if="selectedLocation !== null" icon="mdi-close" iconOnly @click="selectedLocation = null" />
                </v-col>
              </v-row>
            </v-col>
            <v-col align-self="center" cols="auto">
              <mex-btn
                v-if="selectedLocation"
                icon="mdi-refresh"
                iconOnly
                @click="fetchLocationFeatures"
              />
              <mex-btn
                v-else
                :disabled="!selectedClinic"
                icon="mdi-magnify"
                iconOnly
                @click="fetchClinicFeatures"
              />
            </v-col>
          </v-row>
          <mex-sperm-spinner
            v-if="locationFeatureViewLoading"
            :spinnerText="locationFeatureViewLoadingText"
          />
          <template v-else>
            <mex-data-table
              v-if="locationFeatures.length"
              :data="locationFeatures"
              :footer-props="{
                  'items-per-page-options': getTablePagination.rowsPerPage,
                }"
              :headers="locationFeaturesTableHeaders"
              :items-per-page="getTablePagination.defaultRowsPerPage"
              :sortBy="['Feature.keyfield']"
              tableClass="foreground"
              dense
              item-key="FeatureID"
              :show-expand="isClinicFeatures"
              single-expand
            >
              <template v-slot:item.activationDate="{ item }">
                {{ $dateFormatter.convertJsonDate(item.activationDate).full }}
              </template>
              <template v-slot:item.deactivationDate="{ item }">
                {{ $dateFormatter.convertJsonDate(item.deactivationDate).full }}
              </template>
              <template v-slot:item.featureReferenceData="{ item }">
                <mex-btn
                  v-if="item.featureReferenceData != 'null'"
                  icon="mdi-open-in-new"
                  iconOnly
                  @click="viewReference(item.featureReferenceData)"
                />
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="extendedClinicRow">
                  <v-chip-group column class="ma-3">
                    <template v-for="location in item.Locations">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            color="accent"
                            v-bind="attrs"
                            v-on="on"
                            @click="goToLocationView(location.LocationID)"
                          >
                            {{ location.name }}
                          </v-chip>
                        </template>
                        <span>{{ 'Go to location "' + location.name + '"'}}</span>
                      </v-tooltip>
                    </template>
                  </v-chip-group>
                </td>
              </template>
            </mex-data-table>
          </template>
        </v-tab-item>
        <v-tab-item class="pa-5">
          <mex-card cardClass="foreground" icon="mdi-puzzle" title="Features" width="100%">
            <mex-data-table
              :data="featureLocationOverview"
              :headers="featureLocationOverviewTableHeaders"
              :items-per-page="getTablePagination.defaultRowsPerPage"
              dense
              item-key="FeatureID"
              search="Feature search"
              show-expand
              single-expand
              tableClass="foreground"
            >
              <template v-slot:top>
                <v-row justify="end">
                  <v-col cols="auto">
                    <mex-btn
                      icon="mdi-refresh"
                      iconOnly
                      tooltip="refresh the feature-location overview"
                      tooltipPosition="top"
                      @click="fetchFeatureLocationOverview"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.LocationFeatures.length="{ item }">
                {{ item.LocationFeatures.length }}
              </template>
              <template v-slot:item.activationsRelation="{ item }">
                <v-icon
                  v-if="activationsEqual(item)"
                  small
                  style="background-color: var(--v-success-base); border-radius: 20px; padding: 2px;"
                >
                  mdi-equal
                </v-icon>
                <v-icon
                  v-else
                  small
                  style="background-color: var(--v-error-base); border-radius: 20px; padding: 2px;"
                >
                  mdi-not-equal-variant
                </v-icon>
              </template>
              <template v-slot:item.LocationFeatureSettings.length="{ item }">
                {{ item.LocationFeatureSettings.length }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="extendedClinicRow">
                  <mex-data-table
                    :data="getLocationTableData(item)"
                    :headers="locationTableHeaders"
                    :items-per-page="getTablePagination.defaultRowsPerPage"
                    class="ma-3"
                    dense
                    item-key="LocationFeatureID"
                    search="Locations"
                    style="padding: 0"
                    tableClass="foreground"
                  >
                    <template v-slot:item.Location.name="{ item }">
                      <mex-btn
                        :content="item.Location.name"
                        class="pa-1"
                        color="foreground"
                        style="font-size: smaller; height: fit-content"
                        @click="goToLocationView(item.Location.LocationID)"
                      />
                    </template>
                    <template v-slot:item.Location.Clinic.name="{ item }">
                      <mex-btn
                        :content="item.Location.Clinic.name"
                        class="pa-1"
                        color="foreground"
                        style="font-size: smaller ; height: fit-content"
                        @click="goToClinicView(item.Location.Clinic.ClinicID)"
                      />
                    </template>
                    <template v-slot:item.activationDate="{ item }">
                      {{ item.activationDate.replace("Z", "").replace("T", " ").substring(0, 16) }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      <mex-status-lamp
                        :color="checkFeatureStatus(item)"
                      />
                    </template>
                    <template v-slot:item.featureReferenceData="{ item }">
                      <mex-btn
                        v-if="item.featureReferenceData && hasReferenceContent(item.featureReferenceData)"
                        icon="mdi-open-in-new"
                        iconOnly
                        @click="viewReference(item.featureReferenceData)"
                      />
                    </template>
                    <template v-slot:item.LocationFeatureSettings.featureReferenceData="{ item }">
                      <mex-btn
                        v-if="item.LocationFeatureSettings && hasReferenceContent(item.LocationFeatureSettings.featureReferenceData)"
                        icon="mdi-open-in-new"
                        iconOnly
                        @click="viewReference(item.LocationFeatureSettings.featureReferenceData)"
                      />
                    </template>
                  </mex-data-table>
                </td>
              </template>
            </mex-data-table>
          </mex-card>
        </v-tab-item>
        <v-tab-item class="pa-5">
          <mex-card cardClass="foreground" icon="mdi-puzzle" title="Features" width="100%">
            <mex-data-table
              :data="featureClinicOverview"
              :headers="featureClinicOverviewTableHeaders"
              :items-per-page="getTablePagination.defaultRowsPerPage"
              dense
              item-key="FeatureID"
              search="Feature search"
              show-expand
              single-expand
              tableClass="foreground"
            >
              <template v-slot:top>
                <v-row justify="end">
                  <v-col cols="auto">
                    <mex-btn
                      icon="mdi-refresh"
                      iconOnly
                      tooltip="refresh the feature-clinic overview"
                      tooltipPosition="top"
                      @click="fetchFeatureClinicOverview"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.LocationFeatures.length="{ item }">
                {{ item.LocationFeatures.length }}
              </template>
              <template v-slot:item.activationsRelation="{ item }">
                <v-icon
                  v-if="activationsEqual(item)"
                  small
                  style="background-color: var(--v-success-base); border-radius: 20px; padding: 2px;"
                >
                  mdi-equal
                </v-icon>
                <v-icon
                  v-else
                  small
                  style="background-color: var(--v-error-base); border-radius: 20px; padding: 2px;"
                >
                  mdi-not-equal-variant
                </v-icon>
              </template>
              <template v-slot:item.LocationFeatureSettings.length="{ item }">
                {{ item.LocationFeatureSettings.length }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="extendedClinicRow">
                  <mex-data-table
                    :data="getLocationTableData(item)"
                    :headers="clinicTableHeaders"
                    :items-per-page="getTablePagination.defaultRowsPerPage"
                    class="ma-3"
                    dense
                    item-key="LocationFeatureID"
                    search="Clinics"
                    style="padding: 0"
                    tableClass="foreground"
                  >
                    <template v-slot:item.Clinic.name="{ item }">
                      <mex-btn
                        :content="item.Clinic.name"
                        class="pa-1"
                        color="foreground"
                        style="font-size: smaller ; height: fit-content"
                        @click="goToClinicView(item.Clinic.ClinicID)"
                      />
                    </template>
                    <template v-slot:item.locationList="{ item }">
                      <div class="flex-container">
                        <v-chip-group class="chiprow-center">
                          <ChipRow :chip-elements="item.Clinic.locationList" chip-title-property="name" :row-length="80" :at-click="goToLocationView" at-click-param-property="LocationID"/>
                        </v-chip-group>
                      </div>
                    </template>
                  </mex-data-table>
                </td>
              </template>
            </mex-data-table>
          </mex-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
    <reference-dialog
      :showReferenceDialog="showReferenceDialog"
      :selectedReferenceContent="referenceContent"
      @resolve="setReferenceDialogDiscard"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SaveRequest from "../../components/LicSrvComponents/SaveRequest.vue";
import ClinicsService from "../../services/clinics.service";
import LocationsService from "../../services/locations.service";
import OrganizationsService from "../../services/organizations.service";
import LocationFeaturesService from "../../services/locationFeatures.service";
import requiredPermissions from "../../requiredPermissions";
import tablePagination from "../../config/tablePagination.config";
import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable";
import ReferenceDialog from '../../components/LicSrvComponents/ReferenceDialog.vue';
import { assignSeveralProperties } from "../../functions/assignPropertyIfExists";
import ChipRow from "../../components/LicSrvComponents/ChipRow";

export default {
  name: "FeatureLicenseView",
  components: { ChipRow, MexDataTable, SaveRequest, ReferenceDialog, },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getSelectedClinic() {
      return this.selectedClinic;
    },
    getTablePagination() {
      return tablePagination;
    },
    getRetrievalDateMin() {
      return new Date(new Date(new Date().setDate(new Date().getDate() - 15))).toISOString();
    },
    getRetrievalDateMax() {
      return new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString();
    },
    // states if in the location->feature view clinics or locations are inspected
    isClinicFeatures() {
      return this.locationFeatures.some(locationFeature => locationFeature.locationCount);
    },
    // states if the locationFeatureTableHeaders are suitable for clinics or not
    locationFeaturesTableHeadersAreClinicOriented() {
      return this.locationFeaturesTableHeaders[this.locationFeaturesTableHeaders.length - 1]?.value === 'locationCount';
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      showReferenceDialog: false,
      referenceContent: "",
      showSaveRequest: false,
      locationFeatureViewLoading: false,
      locationFeatureViewLoadingText: "loading location features",
      currentTab: 1,
      featureLocationOverviewLoading: false,
      featureLocationOverviewLoadingText: "loading feature location overview",
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      selectedLocation: null,
      organizationNames: [],
      clinicNames: [],
      locationNames: [],
      preSelection: {
        clinic: null,
        organization: null,
        location: null
      },
      retrievalDate: this.$dateFormatter.convertJsonDate(new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString()).date,
      // data
      featureLocationOverview: [],
      featureClinicOverview: [],
      locationFeatures: [],
      alreadyFetchedLocationFeatures: false,
      locationFeaturesTableHeaders: [
        {
          text: "Feature short name",
          align: "start",
          sortable: true,
          value: "Feature.keyfield"
        },
        {
          text: "Feature name",
          align: "start",
          sortable: true,
          value: "Feature.name"
        },
        {
          text: "Activation date",
          align: "center",
          sortable: true,
          value: "activationDate"
        },
        {
          text: "Deactivation date",
          align: "center",
          sortable: true,
          value: "deactivationDate"
        },
        {
          text: "Retrieval date",
          align: "center",
          sortable: true,
          value: "retrievalDate"
        },
        {
          text: "Clinic wide user limit",
          align: "center",
          sortable: true,
          value: "clinicWideUserLimit"
        },
        {
          text: "Location wide user limit",
          align: "center",
          sortable: false,
          value: "locationWideUserLimit"
        },
        {
          text: "Reference data",
          align: "center",
          sortable: false,
          value: "featureReferenceData"
        }
      ],
      hasPermission: false,
      featureLocationOverviewTableHeaders: [
        {
          text: "Feature short name",
          align: "start",
          sortable: true,
          value: "keyfield"
        },
        {
          text: "Feature name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Activations transmitted",
          align: "end",
          sortable: false,
          value: "LocationFeatures.length"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "activationsRelation"
        },
        {
          text: "Activations defined",
          align: "start",
          sortable: false,
          value: "LocationFeatureSettings.length"
        }
      ],
      featureClinicOverviewTableHeaders: [
        {
          text: "Feature short name",
          align: "start",
          sortable: true,
          value: "keyfield"
        },
        {
          text: "Feature name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Activations transmitted",
          align: "end",
          sortable: false,
          value: "LocationFeatures.length"
        },
      ],
      clinicTableHeaders: [
        {
          text: "Clinic",
          align: "start",
          sortable: true,
          value: "Clinic.name"
        },
        {
          text: "Clinic wide user limit",
          align: "center",
          sortable: true,
          value: "clinicWideUserLimit"
        },
        {
          text: "Locations with this feature",
          align: "center",
          sortable: true,
          value: "locationList"
        },
      ],
      locationTableHeaders: [
        {
          text: "Clinic",
          align: "start",
          sortable: true,
          value: "Location.Clinic.name"
        },
        {
          text: "Location",
          align: "start",
          sortable: true,
          value: "Location.name"
        },
        {
          text: "Retrieval date",
          align: "center",
          sortable: true,
          value: "retrievalDate"
        },
        {
          text: "Activation date",
          align: "center",
          sortable: true,
          value: "activationDate"
        },
        {
          text: "Deactivation date",
          align: "center",
          sortable: true,
          value: "deactivationDate"
        },
        {
          text: "Clinic wide user limit",
          align: "center",
          sortable: true,
          value: "clinicWideUserLimit"
        },
        {
          text: "Defined C.W.U.L.",
          align: "center",
          sortable: true,
          value: "LocationFeatureSettings.clinicWideUserLimit"
        },
        {
          text: "Location wide user limit",
          align: "center",
          sortable: false,
          value: "locationWideUserLimit"
        },
        {
          text: "Defined L.W.U.L.",
          align: "center",
          sortable: false,
          value: "LocationFeatureSettings.locationWideUserLimit"
        },
        {
          text: "Reference data",
          align: "center",
          sortable: false,
          value: "featureReferenceData"
        },
        {
          text: "Defined R.D.",
          align: "center",
          sortable: false,
          value: "LocationFeatureSettings.featureReferenceData"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "goToLocationFeatureSettings"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        }
      ],
      clinicFeatureLocationTableHeaders: [
        {
          text: "Location-Name",
          align: "center",
          sortable: true,
          value: "name"
        },
      ],
      searchHappened: false,
    };
  },
  watch: {
    locationFeaturesTableHeadersAreClinicOriented: {
      handler() {
        this.updateLocationFeaturesTableHeaders();
      }
    },
    isClinicFeatures: {
      handler() {
        this.updateLocationFeaturesTableHeaders();
      }
    },
    selectedOrganization: {
      handler() {
        this.$store.commit('selectedProperties/setFeatureLicenseViewOrganization', this.selectedOrganization);
        this.fetchClinicNames();
        this.clearView();
      }
    },
    selectedClinic: {
      handler() {
        this.$store.commit('selectedProperties/setFeatureLicenseViewClinic', this.selectedClinic);
        this.fetchLocationNames();
        this.clearView();
      }
    },
    selectedLocation: {
      handler() {
        if (this.selectedLocation) {
          this.$store.commit('selectedProperties/setFeatureLicenseViewLocation', this.selectedLocation);
          !this.currentTab && this.fetchLocationFeatures();
        } else {
          this.clearView();
        }
      }
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.featureLicense, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchFeatureLocationOverview();
          this.fetchFeatureClinicOverview();
          this.fetchOrganizationNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic', 'location'], this.preSelection, this.$store, 'featureLicenseView');
          if (this.preSelection.organization) {
            this.currentTab = 0;
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    updateLocationFeaturesTableHeaders() {
      if (this.isClinicFeatures) {
        if (!this.locationFeaturesTableHeadersAreClinicOriented) {
          this.locationFeaturesTableHeaders.push({
            text: "Clinic-Locations using this Feature",
            align: "center",
            sortable: false,
            value: "locationCount"
          });
        }
      } else {
        if (this.locationFeaturesTableHeadersAreClinicOriented) {
          this.locationFeaturesTableHeaders.pop();
        }
      }
    },
    clearView() {
      this.locationFeatures = [];
    },
    switchEditMode(value) {
      this.editMode = value;
    },
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.clinicNames = [];
      if (this.selectedOrganization) {
        ClinicsService.getClinicNames(this.selectedOrganization)
          .then((clinicResponse) => {
            clinicResponse.data.forEach((clinic) => {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name
              });
            });
            if (this.preSelection.clinic) {
              this.selectedClinic = this.preSelection.clinic;
              this.preSelection.clinic = null;
              if (!this.preSelection.location) {
                this.fetchClinicFeatures();
              }
            } else {
              this.selectedClinic = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchLocationNames() {
      this.locationNames = [];
      if (this.selectedClinic) {
        LocationsService.getLocationNames(this.selectedClinic)
          .then((locationResponse) => {
            locationResponse.data.forEach((location) => {
              this.locationNames.push({
                value: location.LocationID,
                text: location.name
              });
            });

            if (this.preSelection.location) {
              this.selectedLocation = this.preSelection.location;
              this.preSelection.location = null;
            } else {
              this.selectedLocation = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicFeatures() {
      this.searchHappened = true;
      this.locationFeatureViewLoading = true;
      this.alreadyFetchedLocationFeatures = true;
      this.$store.commit('selectedProperties/setFeatureLicenseViewLocation', null);
      LocationFeaturesService.getLocationFeaturesOfClinic(this.selectedClinic)
        .then((response) => {
          if (response.data.clinicFeatures && response.data.clinicFeatures.length) {
            this.locationFeatures = response.data.clinicFeatures;
            this.locationFeatureViewLoading = false;
          } else {
            this.$toast.info('There are no features for this clinic');
            this.locationFeatureViewLoading = false;
          }
        })
        .catch((err) => {
          this.$toast[err.response.status === 404 ? 'warning' : 'error'](err.response.message);
        });
    },
    fetchLocationFeatures() {
      this.searchHappened = true;
      this.locationFeatureViewLoading = true;
      this.alreadyFetchedLocationFeatures = true;
      LocationFeaturesService.getLocationFeatures(this.selectedLocation)
        .then((response) => {
          if (response.data && response.data.length) {
            this.locationFeatures = response.data;
            this.locationFeatureViewLoading = false;
          } else {
            this.locationFeatures = [];
            this.$toast.info('There are no features for this location');
            this.locationFeatureViewLoading = false;
          }
        });
    },
    fetchFeatureLocationOverview() {
      this.featureLocationOverviewLoading = true;
      LocationFeaturesService.getFeatureLocationOverview().then(response => {
        this.featureLocationOverview = response.data;
        this.featureLocationOverviewLoading = false;
      }).catch(err => {
        this.featureLocationOverviewLoading = false;
        this.$toast.error(err);
      });
    },
    turnFeatureLocationOverviewToFeatureClinicOverview(featureLocationOverview) {
      return featureLocationOverview
        .map((feature) => {
          let addedClinic = new Map();
          feature.LocationFeatures = feature.LocationFeatures
            .filter((locationFeature) => {
              if (!addedClinic.has(locationFeature.Location.Clinic.ClinicID)) {
                return addedClinic.set(locationFeature.Location.Clinic.ClinicID, [locationFeature.Location]);
              }
              return !addedClinic.get(locationFeature.Location.Clinic.ClinicID).push(locationFeature.Location);
            })
            .map((locationFeature) => {
              const { Location, ...otherProps } = locationFeature;
              return {
                Clinic: {...Location.Clinic, locationList: addedClinic.get(Location.Clinic.ClinicID)},
                ...otherProps,
              };
            });
          return feature;
        });
    },
    fetchFeatureClinicOverview() {
      this.featureLocationOverviewLoading = true;
      LocationFeaturesService.getFeatureLocationOverview().then(response => {
        this.featureClinicOverview = this.turnFeatureLocationOverviewToFeatureClinicOverview(response.data);
        this.featureLocationOverviewLoading = false;
      }).catch(err => {
        this.featureLocationOverviewLoading = false;
        this.$toast.error(err);
      });
    },
    getLocationTableData(item) {
      return item.LocationFeatures;
    },
    activationsEqual(item) {
      if (item.LocationFeatures.length != item.LocationFeatureSettings.length) {
        return false;
      } else {
        return true;
      }
    },
    goToLocationView(id) {
      this.$router.push({ name: "LocationView", params: { id } });
    },
    goToClinicView(id) {
      this.$router.push({ name: "ClinicView", params: { id } });
    },
    goToFeatureSettings(organizationID, clinicID, locationID) {
      this.$router.push({
        name: "FeatureLicenseSettings",
        params: { organizationID, clinicID, locationID }
      });
    },
    viewReference(content){
      this.referenceContent = content
      this.showReferenceDialog = true;
    },
    setReferenceDialogDiscard() {
      this.showReferenceDialog = false;
    },
    checkFeatureStatus(item) {
      if (item.LocationFeatureSettings && ((item.activationDate === item.LocationFeatureSettings.activationDate) &&
        (item.deactivationDate === item.LocationFeatureSettings.deactivationDate) &&
        (item.locationWideUserLimit === item.LocationFeatureSettings.locationWideUserLimit) &&
        (item.clinicWideUserLimit === item.LocationFeatureSettings.clinicWideUserLimit))) {
        return "success";
      } else {
        return "error";
      }
    },
    hasReferenceContent(item){
      if(item === 'null'){
        return false
      }
      else return true
    },
  }
};
</script>

<style>
.extendedClinicRow {
  box-shadow: inset 0 0 1em var(--v-primary-base);
}
.chiprow-center {
  display: flex;
  justify-content: center;
}
.flex-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

</style>
