<template>
  <div class="chip-group">
    <div class="chip-row" v-for="(row, i) in chipRows" :key="i">
      <v-chip x-small v-for="(chip, j) in row" :key="j" @click="atClick ? atClick(chip[atClickParamProperty]) : null">{{ chip[chipTitleProperty] }}</v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chipElements: {
      type: Array,
      required: true,
    },
    chipTitleProperty: {
      type: String,
      required: true,
    },
    rowLength: {
      type: Number,
      default: 80,
    },
    atClick: {
      type: Function,
      default: () => {},
    },
    atClickParamProperty: {
      type: String,
      atClickParamProperty: {
        type: String,
        validator(value) {
          // if atClick is defined, atClickParamProperty also needs to be defined
          return !(this.atClick && !value);
        },
      },
    }
  },
  computed: {
    chipRows() {
      let rows = [];
      let currentRow = [];
      let currentLength = 0;

      this.chipElements.forEach((chip) => {
        let chipLength = chip[this.chipTitleProperty].length;

        // check if adding this chip would exceed the row length
        if (currentLength + chipLength > this.rowLength) { // start new row
          rows.push(currentRow);
          currentRow = [chip];
          currentLength = chipLength;
        } else { // add to current row
          currentRow.push(chip);
          currentLength += chipLength;
        }
      });

      // add last row if not empty
      if (currentRow.length) {
        rows.push(currentRow);
      }

      return rows;
    },
  },
};
</script>

<style scoped>
.chip-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chip-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
